/**
 * @author Di Wang
 */
import { createStore, applyMiddleware } from 'redux';
import { combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';

import projectReducer from './redux/project.reducer';
import investorReducer from './redux/investor.reducer';
import snapshotReducer from './redux/snapshot.reducer';

const loggerMiddleware = createLogger();
const reducer = combineReducers({ projectReducer, investorReducer, snapshotReducer });

export type RootState = ReturnType<typeof reducer>;
export const store = createStore(reducer, applyMiddleware(thunkMiddleware, loggerMiddleware));
