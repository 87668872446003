/**
 * @author Di Wang
 */
const object = 'projects';

export class ApiProject {
  public static getAllProjects() {
    return `/${object}`;
  }

  public static getOutstandingProjects() {
    return `/${object}/outstanding`;
  }

  public static getApprovedProjects() {
    return `/${object}/approved`;
  }

  // public static getWeeklyApprovedProjects() {
  //   return `/${object}/weekly-approved`;
  // }

  public static updateProject(projectId: number) {
    return `/${object}/${projectId}`;
  }

  public static deleteProject(projectId: number) {
    return `/${object}/${projectId}`;
  }

  public static updateProjectRemark(projectId: number) {
    return `/${object}/${projectId}/remark`;
  }

  public static updateProjectOrder(projectId: number) {
    return `/${object}/${projectId}/order`;
  }

  public static approveProject(projectId: number) {
    return `/${object}/${projectId}/approve`;
  }

  public static reviewProject(projectId: number) {
    return `/${object}/${projectId}/review`;
  }

  public static pauseProject(projectId: number) {
    return `/${object}/${projectId}/pause`;
  }

  public static rejectProject(projectId: number) {
    return `/${object}/${projectId}/reject`;
  }

  public static resendWelcomeEmail(projectId: number) {
    return `/${object}/${projectId}/resend`;
  }
}
