/**
 * @author Di Wang
 */
import { SnapshotModel } from '../models';

const UPDATE_SNAPSHOTS = 'UPDATE_SNAPSHOTS';

const initialState = {
  snapshots: [],
};

export default function snapshotReducer(state = initialState, action: any) {
  switch (action.type) {
    case UPDATE_SNAPSHOTS:
      return {
        ...state,
        snapshots: action.snapshots,
      };

    default:
      return state;
  }
}

export function updateSnapshots(snapshots: SnapshotModel[]) {
  return {
    snapshots,
    type: UPDATE_SNAPSHOTS,
  };
}
