/**
 * @author Di Wang
 */
import React, { lazy } from 'react';
import {
  HistoryOutlined,
  MailOutlined,
  ContainerOutlined,
  SolutionOutlined,
  IdcardOutlined,
  DollarOutlined,
  SettingOutlined,
  DashboardOutlined,
} from '@ant-design/icons';
import DashboardPage from './dashboard';

export type RouterProps = {
  name: string;
  path: string;
  icon: React.ReactElement;
  component: any;
};

const routers: RouterProps[] = [
  {
    name: 'Dashboard',
    path: '/',
    icon: <DashboardOutlined />,
    component: DashboardPage,
  },
  {
    name: 'Outstanding',
    path: '/outstanding',
    icon: <HistoryOutlined />,
    component: lazy(() => import('./outstanding')),
  },
  {
    name: 'Email Queue',
    path: '/queue',
    icon: <MailOutlined />,
    component: lazy(() => import('./weekly-email')),
  },
  {
    name: 'Past emails',
    path: '/past-email',
    icon: <ContainerOutlined />,
    component: lazy(() => import('./past-email')),
  },
  {
    name: 'Customers',
    path: '/customer',
    icon: <SolutionOutlined />,
    component: lazy(() => import('./customer')),
  },
  {
    name: 'Investors',
    path: '/investor',
    icon: <IdcardOutlined />,
    component: lazy(() => import('./investor')),
  },
  {
    name: 'Discount',
    path: '/discount',
    icon: <DollarOutlined />,
    component: lazy(() => import('./discount')),
  },
  {
    name: 'Settings',
    path: '/setting',
    icon: <SettingOutlined />,
    component: lazy(() => import('./setting')),
  },
];

export default routers;
