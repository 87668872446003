/**
 * @author Di Wang
 */
const object = 'discounts';

export class ApiDiscount {
  public static getDiscounts() {
    return `/${object}`;
  }

  public static createDiscount() {
    return `/${object}`;
  }

  public static updateDiscount(discountId: number) {
    return `/${object}/${discountId}`;
  }

  public static deleteDiscount(discountId: number) {
    return `/${object}/${discountId}`;
  }
}
