/**
 * @author Di Wang on 2/12/19.
 */
import React from 'react';
import echarts from 'echarts/lib/echarts';

require('echarts/lib/chart/bar');
require('echarts/lib/chart/pie');
require('echarts/lib/component/tooltip');
require('echarts/lib/component/title');

export type ChartBaseProps = {
  defaultOption: echarts.EChartOption;
  option?: echarts.EChartOption;
  width?: number | string;
  height?: number | string;
  loading?: boolean;
};

type ChartBaseState = {
  loading: boolean;
  option?: echarts.EChartOption;
};

export class Chart extends React.PureComponent<ChartBaseProps, ChartBaseState> {
  private chartRef = React.createRef<HTMLDivElement>();
  private chart: echarts.ECharts | null = null;

  static defaultProps = {
    width: 530,
    height: 350,
  };

  state = {
    option: {},
    loading: false,
  };

  static getDerivedStateFromProps(nextProps: ChartBaseProps, preState: ChartBaseState) {
    if (preState.loading !== nextProps.loading) {
      return {
        loading: nextProps.loading,
      };
    }

    if (preState.option !== nextProps.option) {
      return {
        option: nextProps.option,
      };
    }

    return null;
  }

  componentDidUpdate(prevProps: ChartBaseProps, prevState: ChartBaseState) {
    if (this.chart) {
      this.state.loading
        ? this.chart.showLoading('default', {
            color: '#1890ff',
            text: 'Loading...',
          })
        : this.chart.hideLoading();
    }

    if (this.chart && prevProps.option !== this.props.option) {
      this.chart.setOption(this.props.option!);
    }
  }

  componentDidMount(): void {
    this.chart = echarts.init(this.chartRef.current!, 'light');
    this.chart.setOption(this.props.defaultOption);
  }

  render() {
    const { width, height } = this.props;
    return <div className="chart-container" ref={this.chartRef} style={{ width, height }} />;
  }
}
