/**
 * @author Di Wang on 01/12/19.
 */
import React from 'react';
import './login.scss';
import axios from 'axios';
import { message } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import { GoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { ApiAuth } from '../../apis';
import { LocalStorageHelper, STORAGE_KEY } from '../../utils/local-storage-helper';

const CLIENT_ID = '762854154811-cgnga4ql17fdt03n17on1n8027o97kpu.apps.googleusercontent.com';

export default class LoginPage extends React.PureComponent<RouteComponentProps, any> {
  _handleLoginResponse = (data: any) => {
    LocalStorageHelper.setItem(STORAGE_KEY.AUTH, data);
    const { user } = data;
    message.success(`Welcome back, ${user.firstName}`);
    this.props.history.replace('/');
  };

  _googleOnSuccess = async (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    if (response.hasOwnProperty('accessToken')) {
      const idToken = (response as GoogleLoginResponse).getAuthResponse().id_token;
      try {
        const res = await axios.post(ApiAuth.loginWithGoogle(), { idToken });
        this._handleLoginResponse(res.data);
      } catch (e) {
        if ('response' in e) {
          message.error(e.response.data.message);
        } else {
          message.error('Login unsuccessfully');
        }
      }
    }
  };

  _googleOnFailure = (response: any) => {
    console.log(response);
    const { error } = response;
    if (error === 'popup_closed_by_user') {
      return message.error('Action terminated by user');
    }
    message.error('Login unsuccessfully');
  };

  render() {
    return (
      <div className="login">
        <div className="login__container">
          <img src={require('../../assets/logo/logo.png')} alt="logo" className="login__logo" />
          <h3 className="login__title">Sign in to Admin Portal</h3>
          <GoogleLogin
            clientId={CLIENT_ID}
            render={(renderProps) => (
              <button
                className="login__login-btn"
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}>
                <div className="login__btn-img-wrapper">
                  <img
                    src={require('../../assets/logo/google-logo.png')}
                    alt="logo"
                    className="login__btn-img"
                  />
                </div>
                <span className="login__btn-label">Log in with STWS Account</span>
              </button>
            )}
            buttonText="Login"
            onSuccess={this._googleOnSuccess}
            onFailure={this._googleOnFailure}
            cookiePolicy={'single_host_origin'}
          />
        </div>
      </div>
    );
  }
}
