/**
 * @author Di Wang
 */
const object = 'investors';

export class ApiInvestor {
  public static getInvestors() {
    return `/${object}`;
  }

  public static createInvestor() {
    return `/${object}`;
  }

  public static updateInvestor(investorId: number) {
    return `/${object}/${investorId}`;
  }
}
