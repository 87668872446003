/**
 * @author Di Wang
 */
const object = 'snapshots';

export class ApiSnapshot {
  public static queueEmails() {
    return `/${object}`;
  }

  public static getThisWeekEmails() {
    return `/${object}/this-week`;
  }

  public static sendTestEmail(snapshotId: number) {
    return `/${object}/${snapshotId}`;
  }

  public static getAllGroupedSnapshots() {
    return `/${object}/groups`;
  }

  public static getSnapshotsByGroup(groupId: number) {
    return `/${object}/groups/${groupId}`;
  }
}
