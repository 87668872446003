import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import './config/antd';
import './config/axios';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from './store';

import LoginPage from './containers/login';
import AuthorisedRoute from './components/authorised-route';
import MainPage from './containers/main';
import Unauthorized from './containers/other/unauthorized';

const App = () => (
  <ReduxProvider store={store}>
    <BrowserRouter>
      <Switch>
        <Route path="/login" component={LoginPage} />
        <Route path="/unauthorized" component={Unauthorized} />
        <AuthorisedRoute path="/" component={MainPage} />
      </Switch>
    </BrowserRouter>
  </ReduxProvider>
);

ReactDOM.render(<App />, document.getElementById('root'));
