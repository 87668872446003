/**
 * @author Di Wang on 2019-12-02.
 */
const STORAGE_KEY = {
  AUTH: 'in-auth-admin',
};

class LocalStorageHelper {
  /**
   * Set value
   * @param key
   * @param value
   */
  public static setItem(key: string, value: {} | string): void {
    const val: string = typeof value === 'string' ? value : JSON.stringify(value);
    localStorage.setItem(key, val);
  }

  /**
   * Get value from key
   * @param key
   * @param parse: if true when the value is a object
   */
  public static getItem(key: string, parse: boolean = true): any {
    let val = localStorage.getItem(key);
    if (!val) {
      return '';
    }

    if (parse) {
      return JSON.parse(val);
    }
    return val;
  }

  /**
   * Remove a value
   * @param key
   */
  public static removeItem(key: string): void {
    localStorage.removeItem(key);
  }
}

export { LocalStorageHelper, STORAGE_KEY };
