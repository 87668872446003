/**
 * @author Di Wang
 */
const object = 'schedules';

export class ApiSchedule {
  public static getAllSchedules() {
    return `/${object}`;
  }

  public static updateSchedules() {
    return `/${object}`;
  }
}
