import React, { Suspense } from 'react';
import './main.scss';
import { Layout, Menu } from 'antd';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';
import routers from './routers';
import SuspenseLoader from '../../components/suspense-loader';
import { version } from '../../../package.json';
import PageHeader from '../../components/header';

const { Sider, Content } = Layout;

export default class MainPage extends React.PureComponent<RouteComponentProps, any> {
  state = {
    collapsed: false,
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  componentDidMount(): void {}

  render() {
    const { collapsed } = this.state;
    const { pathname } = this.props.location;
    const defaultSelectedKeys = pathname === '/' ? routers[0].path : pathname;
    return (
      <Layout className="main">
        <Sider
          collapsible
          trigger={null}
          collapsed={collapsed}
          width={230}
          className="main__sidebar">
          <div className="main__logo-container">
            <img
              className="main__logo"
              src={
                collapsed
                  ? require('../../assets/logo/logo-square.png')
                  : require('../../assets/logo/logo_white.png')
              }
              alt="logo"
            />
          </div>
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={[defaultSelectedKeys]}
            onClick={({ item, key }) => {
              this.props.history.push(key);
            }}>
            {routers.map((router) => (
              <Menu.Item key={router.path}>
                {router.icon}
                <span>{router.name}</span>
              </Menu.Item>
            ))}
          </Menu>
          <div className="main__sidebar-footer">version: {version}</div>
        </Sider>
        <Layout className="main__layout" style={{ marginLeft: collapsed ? 80 : 230 }}>
          <PageHeader collapsed={collapsed} collapsedOnClick={this.toggle} />
          <Content className="main__content">
            <Suspense fallback={<SuspenseLoader />}>
              <Switch>
                {routers.map((router) => (
                  <Route
                    exact={router.path === '/'}
                    key={router.name}
                    path={router.path}
                    component={router.component}
                  />
                ))}
              </Switch>
            </Suspense>
          </Content>
        </Layout>
      </Layout>
    );
  }
}
