/**
 * @author Di Wang
 */
import axios from 'axios';
import { LocalStorageHelper, STORAGE_KEY } from '../utils/local-storage-helper';

axios.defaults.baseURL = '/v1';

axios.interceptors.request.use(
  (config) => {
    let auth = LocalStorageHelper.getItem(STORAGE_KEY.AUTH);
    if (auth) {
      try {
        config.headers.Authorization = `Bearer ${auth.accessToken}`;
      } catch (e) {
        console.log(e);
      }
    }

    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if (err.response) {
      switch (err.response.status) {
        case 401:
          window.location.replace('/unauthorized');
          break;

        case 403:
          window.location.replace('/unauthorized');
          break;

        default:
          break;
      }
    }

    return Promise.reject(err);
  }
);

export default axios;
