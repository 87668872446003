import React from 'react';
import { Layout, Modal } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined, PoweroffOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { LocalStorageHelper, STORAGE_KEY } from '../../utils/local-storage-helper';

const { Header } = Layout;

type Props = {
  collapsed: boolean;
  collapsedOnClick: () => void;
};

const PageHeader = (props: Props) => {
  const { collapsed, collapsedOnClick } = props;
  const history = useHistory();
  const { user } = LocalStorageHelper.getItem(STORAGE_KEY.AUTH);
  let firstName, lastName;
  if (user) {
    const { firstName: _firstName, lastName: _lastName } = user;
    firstName = _firstName;
    lastName = _lastName;
  }

  const logout = () => {
    Modal.confirm({
      title: 'Info',
      content: 'Are you sure to log out?',
      onOk: () => {
        LocalStorageHelper.removeItem(STORAGE_KEY.AUTH);
        history.replace('/login');
      },
    });
  };

  return (
    <Header className="main__header">
      {collapsed ? (
        <MenuUnfoldOutlined className="main__trigger" onClick={collapsedOnClick} />
      ) : (
        <MenuFoldOutlined className="main__trigger" onClick={collapsedOnClick} />
      )}
      <div>
        {firstName} {lastName}
        <PoweroffOutlined style={{ marginLeft: 20, fontSize: 15 }} onClick={logout} />
      </div>
    </Header>
  );
};

export default PageHeader;
