import React from 'react';
import './suspense-loader.scss';
import { Spin } from 'antd';

const SuspenseLoader = () => (
  <div className="suspense-loader">
    <Spin className="suspense-loader__loader" />
  </div>
);

export default SuspenseLoader;
