/**
 * @author Di Wang
 */
import { Investor } from '../models';

const UPDATE_INVESTORS = 'UPDATE_INVESTORS';

export type InvestorState = {
  investors: Investor[];
};

const initialState: InvestorState = {
  investors: [],
};

export default function investorReducer(state = initialState, action: any) {
  switch (action.type) {
    case UPDATE_INVESTORS:
      return {
        ...state,
        investors: action.investors,
      };

    default:
      return state;
  }
}

export function updateInvestors(investors: Investor[]) {
  return {
    investors,
    type: UPDATE_INVESTORS,
  };
}
