/**
 * @author Di Wang on 2019-12-01.
 */
import React from 'react';
import './unauth.scss';
import { Button } from 'antd';
import { History } from 'history';

type UnauthorizedProps = {
  history: History;
};

export default class Unauthorized extends React.PureComponent<UnauthorizedProps, any> {
  render() {
    return (
      <div className="unauthorized">
        <div className="unauthorized__container">
          <img
            className="unauthorized__logo"
            src={require('../../../assets/logo/logo.png')}
            alt="logo"
          />
          <p className="unauthorized__desc">
            Your session is invalid or expired. <br />
            Please log in again.
          </p>
          <Button
            onClick={() => this.props.history.replace('/login')}
            className="unauthorized__btn"
            type="primary"
            size="large">
            Log in
          </Button>
        </div>
      </div>
    );
  }
}
