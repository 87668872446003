/**
 * @author Di Wang on 2019-12-02.
 */
import React, { Component } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { LocalStorageHelper, STORAGE_KEY } from '../../utils/local-storage-helper';

export default class AuthorisedRoute extends Component<RouteProps, any> {
  render() {
    let auth = LocalStorageHelper.getItem(STORAGE_KEY.AUTH);
    if (auth) {
      return <Route {...this.props} />;
    }

    return (
      <Redirect
        to={{
          pathname: '/unauthorized',
          state: { from: this.props.location },
        }}
      />
    );
  }
}
