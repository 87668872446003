import React from 'react';
import './dashboard.scss';
import { message, Row, Col, Spin } from 'antd';
import {
  QuestionCircleTwoTone,
  CheckCircleTwoTone,
  PauseCircleTwoTone,
  StopTwoTone,
} from '@ant-design/icons';
import axios from 'axios';
import echarts from 'echarts/lib/echarts';
import { Project } from '../../../models';
import { Chart } from '../../../components/chart';
import { ApiProject } from '../../../apis';
import { ProjectStatus } from '../../../config/parameters';
import moment from 'moment';

const PieChartDefaultOptions: echarts.EChartOption = {
  title: {
    text: 'Paid / Unpaid Rate',
  },
  color: ['#1890ff', '#546570', '#c4ccd3', '#c23531', '#2f4554', '#61a0a8', '#d48265', '#91c7ae'],
  tooltip: {
    trigger: 'item',
  },
  legend: {
    data: ['Loading...'],
  },
};

const BarChartDefaultOptions: echarts.EChartOption = {
  title: {
    text: 'Submission in the past 30 days',
  },
  color: ['#1890ff', '#546570', '#c4ccd3', '#c23531', '#2f4554', '#61a0a8', '#d48265', '#91c7ae'],
  tooltip: {
    trigger: 'item',
  },
  legend: {
    data: ['Loading...'],
  },
};

type States = {
  projects: Project[];
  isFetching: boolean;
};

export default class Dashboard extends React.PureComponent<any, States> {
  state: States = {
    projects: [],
    isFetching: false,
  };

  private getAllProjects = async () => {
    this.setState({ isFetching: true });
    try {
      const res = await axios.get(ApiProject.getAllProjects());
      const projects = res.data as Project[];
      this.setState({ projects, isFetching: false });
    } catch (e) {
      message.error('Get data unsuccessfully');
      this.setState({ isFetching: false });
    }
  };

  componentDidMount(): void {
    this.getAllProjects();
  }

  renderStatsBanner = () => {
    const { isFetching, projects } = this.state;
    let review = 0,
      approved = 0,
      paused = 0,
      rejected = 0;
    projects.forEach((project) => {
      switch (project.status) {
        case ProjectStatus.APPROVED:
          approved++;
          break;
        case ProjectStatus.REVIEW:
          review++;
          break;
        case ProjectStatus.PAUSED:
          paused++;
          break;
        case ProjectStatus.REJECTED:
          rejected++;
          break;
      }
    });
    return (
      <div className="dashboard__stats-banner">
        <h2 className="dashboard__header">Total Submission Matrix</h2>
        <Row>
          <Col span={6} className="dashboard__col">
            <div className="dashboard__stats-title">
              <QuestionCircleTwoTone /> In Review
            </div>
            <Spin spinning={isFetching}>
              <div className="dashboard__stats-num">{review}</div>
            </Spin>
          </Col>
          <Col span={6} className="dashboard__col">
            <div className="dashboard__stats-title">
              <CheckCircleTwoTone twoToneColor="#52c41a" /> Approved
            </div>
            <Spin spinning={isFetching}>
              <div className="dashboard__stats-num">{approved}</div>
            </Spin>
          </Col>
          <Col span={6} className="dashboard__col">
            <div className="dashboard__stats-title">
              <PauseCircleTwoTone twoToneColor="#fa8c16" /> Paused
            </div>
            <Spin spinning={isFetching}>
              <div className="dashboard__stats-num">{paused}</div>
            </Spin>
          </Col>
          <Col span={6}>
            <div className="dashboard__stats-title">
              <StopTwoTone twoToneColor="#f5222d" /> Rejected
            </div>
            <Spin spinning={isFetching}>
              <div className="dashboard__stats-num">{rejected}</div>
            </Spin>
          </Col>
        </Row>
      </div>
    );
  };

  renderChart = () => {
    const { isFetching, projects } = this.state;
    let paid = 0,
      unpaid = 0;
    projects.forEach((project) => {
      project.isPaid ? paid++ : unpaid++;
    });
    const map = new Map();
    let date = moment();
    for (let i = 0; i < 30; i++) {
      map.set(date.format('DD/MM'), 0);
      date.subtract(1, 'days');
    }
    projects
      .filter((project) => {
        const submittedDate = moment(project.createdAt);
        return submittedDate.isAfter(moment().subtract(30, 'days'));
      })
      .forEach((project) => {
        const key = moment(project.createdAt).format('DD/MM');
        if (map.has(key)) {
          map.set(key, map.get(key) + 1);
        }
      });

    return (
      <Row>
        <Col span={12}>
          <Chart
            width={'100%'}
            height={300}
            loading={isFetching}
            defaultOption={BarChartDefaultOptions}
            option={{
              xAxis: {
                data: Array.from(map.keys()),
              },
              yAxis: {},
              series: [
                {
                  type: 'bar',
                  data: Array.from(map.values()),
                },
              ],
            }}
          />
        </Col>
        <Col span={12}>
          <Chart
            width={'100%'}
            height={300}
            loading={isFetching}
            option={{
              legend: {
                data: ['Paid', 'Unpaid'],
              },
              series: [
                {
                  type: 'pie',
                  radius: '65%',
                  center: ['50%', '50%'],
                  selectedMode: 'single',
                  data: [
                    { value: paid, name: 'Paid' },
                    { value: unpaid, name: 'Unpaid' },
                  ],
                  emphasis: {
                    itemStyle: {
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: 'rgba(0, 0, 0, 0.5)',
                    },
                  },
                },
              ],
            }}
            defaultOption={PieChartDefaultOptions}
          />
        </Col>
      </Row>
    );
  };

  render() {
    return (
      <div className="dashboard">
        {this.renderStatsBanner()}
        {this.renderChart()}
      </div>
    );
  }
}
