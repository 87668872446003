// Cut off time (New York time)
export const segmentAt = '0 10 * * 4';

// Region table
export const Region = {
  APAC: 1,
  EMEA: 2,
  'North America': 3,
};

export const IndustrySegments = ['Sports Performance', 'Sport Business'];

export const BusinessModels = ['B2B', 'B2C'];

export const FundingStages = ['Pre-Seed', 'Seed', 'Series A', 'Series B', 'Series C or Beyond'];

export const ProjectStatus = Object.freeze({
  APPROVED: 'Approved',
  REVIEW: 'Review',
  PAUSED: 'Paused',
  REJECTED: 'Rejected',
});
