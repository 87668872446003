/**
 * @author Di Wang
 */
import { Project } from '../models';

const UPDATE_OUTSTANDING_PROJECTS = 'UPDATE_OUTSTANDING_PROJECTS';

export type ProjectState = {
  outstandingProjects: Project[];
};

const initialState: ProjectState = {
  outstandingProjects: [],
};

type UpdateOutstandingProjectAction = {
  type: typeof UPDATE_OUTSTANDING_PROJECTS;
  projects: Project[];
};
export function updateOutstandingProjects(
  outstandingProjects: Project[]
): UpdateOutstandingProjectAction {
  return {
    type: UPDATE_OUTSTANDING_PROJECTS,
    projects: outstandingProjects,
  };
}

export type ProjectActionTypes = UpdateOutstandingProjectAction;

export default function projectReducer(state = initialState, action: ProjectActionTypes) {
  switch (action.type) {
    case UPDATE_OUTSTANDING_PROJECTS:
      return {
        ...state,
        outstandingProjects: action.projects,
      };

    default:
      return state;
  }
}
